import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ulastirma1 from "../../assets/img/ulastirma-sol.png"
import ulastirma2 from "../../assets/img/ulastirma-sag.png"

export default function UlastirmaBakanligi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Ulaştırma Bakanlığı Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={ulastirma1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("ulastirma")}
            <br />
            <span>{t("project-sector")}</span>: {t("kamu-kurumu")}
            <br />
            <span>{t("project-subject")}</span>: {t("kanal-istanbul")}
            <br />
            <span>{t("project-artwork")}</span>: {t("araf-suresi")}
          </div>
          <img src={ulastirma2} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
